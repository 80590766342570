<template>
  <Layout class="pb-5">
    <PageHeader :title="title" :items="items" />
    <Alert :message="requestError" type="alert-danger" v-if="requestError" />

    <div class="row">
      <div class="col-md-12 content_margin_bottom">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Card -->

            <div class="table-responsive mb-0 courses__row">
              <div
                class="courses__col"
                v-for="course in courses"
                :key="course.course.id"
              >
                <b-card
                  :title="course.course.title"
                  class="m-0"
                  :img-src="url + course.course.avatar"
                  @click="getDetails(course.course_id, course.individual_id)"
                  :img-alt="course.course.title"
                  img-top
                >
                  <b-card-text>
                    {{ course.course.overview | truncate(40, "...") }}
                  </b-card-text>
                </b-card>
                <div class="d-flex justify-content-end bg-light p-2">
                  <button
                    @click="unenroll(course.course_id, course.individual_id)"
                    class="btn btn-danger"
                  >
                    <span>Unenroll</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
/* eslint-disable */
import Layout from "@/views/layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Toaster from "@/mixins/toasterMixin.js";
// import url from "@/helpers/url.js";

export default {
  page: {
    title: "Courses",
    meta: [{ name: "description", content: appConfig.description }],
  },

  components: {
    PageHeader,
    Layout,
  },

  data() {
    return {
      // data table meta
      userId: this.$route.params.id,
      url: `https://staging.afcfta.app`,
      requestError: null,
      totalRows: 1,
      currentPage: 1,
      filter: null,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      title: "My Courses",
      items: [
        {
          text: "User",
        },
        {
          text: "My Courses",
          active: true,
        },
      ],

      courses: [],
      enrolledCourses: [],
    };
  },
  computed: {
    // url() {
    //   return url;
    // },
    rows() {
      return this.courses.courses && this.courses.courses.length;
    },
    users() {
      return this.$store.getters["enroll/mycourse"];
    },
    enrolledIds() {
      if (!this.courses || this.courses.length == 0) return [];

      const ids = this.courses.map((course) => course.id);
      console.log(ids);
      return ids;
    },
  },
  filters: {
    truncate: function(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  methods: {
    getDetails(courseId, individualId) {
      this.$router.push(
        `/user/${this.userId}/enroll_course/${courseId}/details/${individualId}`
      );
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    unenroll(courseId, individualId) {
      const data = {
        courseId: courseId,
        individualId: individualId,
      };
      this.$store
        .dispatch("enroll/unEnrollCourse", data)
        .then((response) => {
          if (response) {
            this.fetchData();
            this.toast({ message: "Unenrolled from Course", type: "success" });
          }
        })
        .catch((err) => {
          this.requestError = "An error occured";
        });
    },
    fetchData() {
      this.$store
        .dispatch("enroll/myCourses", this.userId)
        .then((response) => {
          console.log("mycourses", response.data);
          this.courses = [...response.data.Enrollments];
        })
        .catch((err) => {
          if (err?.response?.data === undefined)
            this.requestError = "An error occured";
        });
    },
  },

  created() {
    this.fetchData();
  },

  mounted() {},
};
</script>

<style>
.card img {
  width: 100%;
  height: 340px;
  display: block;
  cursor: pointer;
  margin: 0 auto 30px;
  background-size: cover;
  background-position: center center;
}

.courses__row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 1rem;
  padding: 10px;
}

.courses__col {
  height: 400px;
  height: auto;
  width: 100%;
  box-shadow: 2px 2px 2px 1px rgba(138, 137, 137, 0.2);
}

.courses__colText {
  padding: 10px;
}

.courses__colTitle {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
